body.page-film {
  .mobile-top {
    display: none;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }
    width: 100%;
    aspect-ratio: 1;
    height: auto;
    .mobile-top-panel {
      width: 100%;
      height: 100%;
    }
    margin-bottom: 40px;
    .film-titles {
      position: absolute;
      top:0;
      left:0;
      height: 100%;
      width: 100%;
      .capper, .capper-padded {
        height: 100%;
      }
      .film-titles2 {
        min-height: unset;
        height: 100%;
      }
      ul {
        bottom: 20px;
      }
    }
    .video-play {
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      .icon {
        width: 100%;
        height: auto;
        svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }

}