
//LEAVE AT BOTTOM - desktop, mobile global hiders
.show-desktop {
  display: inline-block;
  &.block {
    display: block;
  }
  &.flex {
    display: flex;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: none;
    &.block {
      display: none;
    }
    &.flex {
      display: none;
    }
  }
}
.show-mobile {
  display: none;
  &.block {
    display: none;
  }
  &.flex {
    display: none;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: inline-block;
    &.block {
      display: block;
    }
    &.flex {
      display: flex;
    }
  }
}
