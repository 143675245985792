#search-bar {
  visibility: hidden;
  overflow: hidden;
  height: 0;
  z-index: 200000;

  .search2 {
    margin-top: 50px;
  }

  .close-holder {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .close-button {

    }
  }

  .inputs {
    width: 100%;
    margin-top: 20px;
    #search-submit {
      position: absolute;
      background-color: transparent;
      border: 0;
      top:12px;
      right:0;
      z-index:2;
      cursor: pointer;
      svg {
        @include transition-all();
      }
      &:hover {
        svg {
          opacity: 0.7;
        }
      }


    }
    input {
      border:0;
      width: calc(100% - 40px);
      @include font-adrianna-light();
      font-size: 28px;
      color:#0D0D0D;
      padding:10px 20px;
    }
    border-bottom: 1px solid #0D0D0D;
  }
}
