/////////////////////////////////////////////////////////
//GLOBAL TAGS
/////////////////////////////////////////////////////////
html, body {
  background-color: $color-light;
  scroll-behavior: smooth;
}

body {
  @include vh100();
  &.no-scroll {
    overflow: hidden;
    //height: 100%;
  }
}
.scroll-check-trigger {
  overflow: visible;
}

.main-content {

}

#container {
  width: 100%;
}

#content {
  //@include vh100();
}
.content-block {

}

#meta-sitewidth {
  display: none;
  z-index: 0;
  padding-top: $max-width-site;
}
#meta-breakpoint {
  display: none;
  z-index: 0;
  padding-top: $breakpoint-small;
}

a {
  text-decoration: none;
}
.site-content {
  //overflow-x: hidden;
  //overflow-y: visible;
}

.generic-holder {
  @include master-padding-tb();
  min-height: 300px;

  .generic-holder-content {
    padding-top: 40px;
  }
}



.generic-lr {
  display: flex;
  justify-content: space-between;
  .l {
    width: 100%;
    margin-right: 100px;
  }
  .r {
    @include widthMaxMin(375px);
  }
  @media only screen and (max-width: $breakpoint-small) {
    flex-wrap: wrap;
    .l {
      width: 100%;
      margin-right: 0
    }
    .r {
      @include widthMaxMin(100%);
      margin-top: 40px;
    }
  }
}


.strip-hider-trigger {
  .quick-thumbs-side {
    visibility: hidden;
    opacity: 0;
    @include transition-all();
    display: none;
    &.more-slides {
      display: flex;
    }
    &.initial-hide {
      opacity: 0;
      @include transition-all();
      visibility: hidden;
    }
  }
  &:hover {
    .quick-thumbs-side {
      visibility: visible;
      opacity: 1;
      &.initial-hide {
        opacity: 0;
        @include transition-all();
        visibility: hidden;
      }
    }
  }
}

.grid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 15px;
  row-gap: 35px;
  margin-bottom: 60px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $breakpoint-small) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
  }
  @media (max-width: $breakpoint-tiny) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
    row-gap: 5px;
  }
  $breakpoint-quickthumbs: 550px;
  .grid-item {
    display: block;
    aspect-ratio: 1;
    cursor: pointer;
    @include punch-in-hover();

    &.images-strip {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.videos-strip {
      aspect-ratio: unset;
    }

    .bgimg {
      opacity:1;
      @include transition-all();
    }
    .bgimg-outer {
       background-color: #000;
     }
    .bgimg-outer, .bgimg-outer a {
      display: block;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
    }
    .bottom-bar {
      position: absolute;
      bottom:0;
      left:0;
      width: 100%;
      .bottom-bar2 {
        padding:10px;
        .bottom-bar3 {
          display: flex;
          width: 100%;
          align-items: flex-end;
          justify-content: space-between;
          .l {
            width:calc(100% - 50px);
            a {
              color:#fff;
              text-transform: uppercase;
              //@include font-adrianna-condensed-light();
              @include font-adrianna-condensed-demibold();
              font-size: 24px;
              @include link-underline-leftright();
              top:9px;
              @media only screen and (max-width: $breakpoint-small) {
                //@include font-adrianna-condensed-demibold();
              }
            }

          }
          .r {
            width:auto;
            //margin-right: 15px;
          }
        }
      }
    }

    .download-icon {
      position: absolute;
      bottom:10px;
      left:0;
      width: 100%;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      display: flex!important;
      @include transition-all();
      opacity: 0;
      .download-icon2 {
        @extend .link-underline-leftright;

      }
      a {
        display: inline-block;
      }

      span {
        color:#fff;
        @include font-adrianna-regular();
        margin-left: 10px;
        text-transform: uppercase;
        font-size: 16px;
        @include pop-out-box-subtle();
      }
      svg {
        width: 15px;
        @include transition-all();
        path {
          fill:#fff;
        }
      }
    }

    &.video-strip {
      .quick-thumbs-outer .swiper-slide .bgimg {
        aspect-ratio: unset;
        width:100%;
        height: 100%;
      }

    }

    &:hover {
      .download-icon {
        opacity: 1;
        svg {
          //transform: scale(1.2);
        }
      }

    }
  }
}