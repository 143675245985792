footer.footer {
  margin-top: 80px;
  border-top:1px solid #000;
  .footer2 {
    padding:60px 0;
  }

  .legal-lr {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }
    .rowline {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 10px;
      }
    }
    .main-logo {
      img, svg {
        width: 150px;
      }
    }
    .legal {
      @include font-adrianna-regular();
      font-size: 14px;
      line-height: 1.5em;
      text-transform: uppercase;
    }
    .nav, .mininav {
      ul {
        li {
          list-style: none;
        }
      }
    }
    .nav {
      ul {
        li {
          a, .alink {
            @extend .link-underline-leftright-dark;
            @include font-adrianna-regular();
            font-size: 18px;
            color:#000;
            text-transform: uppercase;
          }
        }
      }
    }
    .mininav {
      @media only screen and (max-width: $breakpoint-small) {
        margin-top: 20px;
      }
      ul {
        li {
          display: inline;
          word-break: keep-all;
          text-wrap: none;
          margin-right: 15px;
          a, #ot-sdk-btn {
            @extend .link-underline-leftright-dark;
            @include font-adrianna-regular();
            font-size: 12px;
            line-height: 1.7em;
            color:#000;
            text-transform: uppercase;
          }
          #ot-sdk-btn {
            background-color: transparent;
            border: 0;
            padding:0 0 5px;
            margin: 0;
            top:-10px;
          }
          .spacer {
            top:-9px;
            margin-right: 15px;
          }
          &:first-child {
            display: none;
          }
        }
      }
    }
    .l {
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: $breakpoint-small) {
        display: block;
      }
      .l1 {
        @include widthMaxMin(250px);
      }
    }
    .r {
      width: 100%;
      margin-left: 30px;
      @media only screen and (max-width: $breakpoint-small) {
        margin-left: 0;
      }
    }
  }

  .r-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }
    .rowline {
      width: 100%;
    }
    &.row-social {
      justify-content: flex-end;
    }
    &.legal-mobile {
      margin-top: 20px;
    }
    .mininav {
      //max-width: calc(100% - 350px);
      width: 100%;
    }
  }
}
