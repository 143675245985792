header.header {
  .l {
    .logo {
      svg {
        polygon, path {
          fill: #000;
        }
      }
    }
  }
  .r {
    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
      background-color: #000;
    }
  }
}
@media only screen and (max-width: $breakpoint-small) {
  body.mode-nav header.header {
    .l {
      .logo {
        svg {
          polygon, path {
            fill: #fff!important;
          }
        }
        .txt {
          color:#fff!important;
        }
      }
    }
  }
}


.hide-element {
  @include transition-all();
}
#header-scrim {
  opacity:0;
  visibility: hidden;
  background-color: rgba(255,255,255,0);
  @include transition-all();
  width: 100%;
  position: fixed;
  top:0;
  left:0;
  z-index: 10;
  height: 154px;
  pointer-events: none;
}

body.scroll-dir-up {
  .hide-element {
    opacity:1;
    visibility: visible;
  }
  #header-scrim {
    opacity:1;
    visibility: visible;
    background-color: rgba(255,255,255,1);
  }

}


body.mode-film,
body.mode-nav,
body.mode-buy {
  &.scroll-dir-down,
  &.scroll-dir-up {
    .header {
      opacity: 1!important;
      visibility: visible!important;
    }
    #mobile-nav-hamburger {
      opacity: 1!important;
      visibility: visible!important;
    }
  }
}



body.page-home header.header .r .is-active .hamburger-inner,
body.page-film header.header .r .is-active .hamburger-inner {
  background-color: rgba(255,255,255,0) !important;

}

body.scroll-dir-down {
  .hide-element {
    opacity:0;
    visibility: hidden;
  }
  #header-scrim {
    opacity:0;
    visibility: hidden;
    background-color: rgba(255,255,255,0);
  }

}