$mobilenav_hamburger_off: #000;
$mobilenav_hamburger_on: #fff;
$headerHeightSmall: 0;

$breakBig:900px;
$break1:800px;

#mobile-nav-hamburger {
  display: block;
  position: relative;
  top: 0;
  right: 0;
  z-index: 2;
}
.hamburger {
  padding: 0!important;
}
.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  background-color: $mobilenav_hamburger_off;
  border-radius: 0;
}
.is-active .hamburger-inner,
.is-active .hamburger-inner::after,
.is-active .hamburger-inner::before {
  background-color: $mobilenav_hamburger_on !important;
}

#mobile-nav-hamburger.mode-film {
  .is-active .hamburger-inner {
    background-color: rgba(255,255,255,0) !important;
  }
  .is-active .hamburger-inner::after,
  .is-active .hamburger-inner::before {
    background-color: #fff !important;
  }
}


#mobile-nav-overlay-outer {
  background-color: #000;
  display: block;
  position: fixed;
  top:0;
  right:0;
  width: 500px;
  z-index: 1000;
  overflow: visible;
  @include vh100();
  @include transition-all();
  &.hidden {
    right:-500px;
  }
  @media only screen and (max-width: $breakpoint-small) {
    width: 100%;
    &.hidden {
      right:-100%;
    }
  }
  #mobile-nav-overlay {
    display: block;
  }
  #film-nav-overlay {
    display: none;
  }
  #film-buy-overlay {
    display: none;
  }

  .mobile-content0 {
    position: absolute;
    left:40px;
    top:30px;
    height: calc(100% - 160px);
    width: calc(100% - 80px);
    @media only screen and (max-width: $breakpoint-small) {
      left:20px;
      width: calc(100% - 20px); //NOTE: width should be 40 but scroll is ugly so make 20
    }
    .mobile-content1 {
      padding-top:100px;
      display: block; // was flex - center align logic is now dead
      align-items: flex-start;
      justify-content: left;
      overflow: auto;
      width:100%;
      height: 100%;
      &.block {
        display: block;
      }
      @media only screen and (max-height: $break1) {
        display: block;
      }
      .mobile-content2 {
        width: 100%;
        @media only screen and (min-height: $breakBig) {
          width: calc(100% - 20px);  //NOTE: PART 2 of width should be 40 but scroll is ugly so make 20
        }
        /*
        @media only screen and (min-height: $breakBig) {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          flex-direction: column;
        }

         */
      }
    }
  }

  .main-menu {
    width: 100%;
    padding-bottom: 40px;
    ul {
      li {
        list-style: none;
        margin:20px 0;
        @media only screen and (max-height: $break1) {
          margin:10px 0;
        }
        a, .alink {
          @extend .link-underline-leftright;
          @include font-adrianna-condensed-regular();
          font-size: 24px;
          color:#fff;
          text-transform: uppercase;
        }
      }
    }
  }
  .social {
    margin-top: 30px;
  }
  .member {
    margin-top: 50px;
    .rte {
      h1 {
        font-size: 32px;
      }
      .email-member-strip {
        margin-top: 15px;
      }
    }
  }



  ////////////////
  &.mode-nav {

  }
  &.mode-film {
    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
      background-color: #fff;
    }

    background-color: #000;
    #mobile-nav-overlay {
      display: none;
    }
    #film-nav-overlay {
      display: block;
    }
    #film-buy-overlay {
      display: none;
    }
    .rte input {
      color:#000!important;
      //@include set-input-placeholder-color(#000);
    }
    .rte *, h1 {
      color:#fff!important;
    }


    #ellipsis-link {
      @include font-adrianna-regular();
      font-size: 16px;
      color:#fff;
      @extend .link-underline-leftright;
      svg {
        margin-left: 5px;
        top:1px;
        width: 14px;
        height: 14px;
      }
    }

    .bugs-outer {
      .bug {
        margin:30px 0;
        min-height: 40px;
        .bug-lr {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
        }
        * {
          @include font-adrianna-regular();
          font-size: 18px;
          color:#fff;
        }
        .bug-label {
          @extend .link-underline-leftright;
          text-transform: uppercase;
          word-break: break-all;
          top:4px;
        }
        .bug-icon {
          margin-left: 20px;
          img {
            //height:30px; //set via TWIG
            width: auto;
          }
        }
      }
    }
  }

  &.mode-buy {
    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
      background-color: #fff!important;
    }
    background-color: #000;
    #mobile-nav-overlay {
      display: none;
    }
    #film-nav-overlay {
      display: none;
    }
    #film-buy-overlay {
      display: block;
    }
    .rte input {
      color:#000!important;
    }
    .rte *, h1 {
      color:#fff!important;
    }
    .rte {
      h3 {
        @include font-adrianna-condensed-demibold();
        font-size: 25px;
      }
    }

    .bugs-outer {
      margin-top: 40px;
      .bug-none {
        margin-top: 20px;
      }
      .bug {
        margin:20px 0;
        min-height: 50px;
        .bug-lr {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
        }


        .bug-label {
          //@extend .link-underline-leftright;
          text-transform: uppercase;
          margin-left: 20px;
          width: 100%;
          @include font-adrianna-regular();
          font-size: 18px;
          color:#fff;
          word-break: break-all;
        }
        .bug-icon {
          top:0;
          @include widthMaxMin(60px);
          .bug-icon2 {
            text-align: center;
            img {
              display: inline-block;
              height:auto;
              //width: 100%; HANDLED VIA TWIG
            }
          }
        }
        .bug-buy {
          margin-left: 20px;
          @include widthMaxMin(120px);
          * {
            @include font-roboto-medium();
            font-size: 18px;
            color:#fff;
          }
        }
      }
    }
  }
}
