.link-underline-grow {
  display: inline-block;
  text-decoration: none;
  &:after {
    content:"";
    display: block;
    width:100%;
    height:2px;
    background-color: #000;
    @include transition_all();
    transform-origin: top center;
  }
  &:hover {
    &:after {
      transform: scaleY(2);
    }
  }
}
.link-underline-leftright {
  cursor:pointer;
  $transTime:250ms;
  $transEaseInOut:all $transTime ease-in-out;
  $transEaseIn:all $transTime ease-in;
  $transEaseOut:all $transTime ease-out;
  $transOff:all 0ms ease-in-out;
  color:#fff;
  transition: $transEaseInOut;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
  padding-bottom: 3px;
  &:before,
  &:after {
    content:"";
    position: absolute;
    @include calc(top,'100% - 4px');
    @include calc(left,'-100%');
    display: block;
    width:100%;
    height:1px;
    background-color: #fff;
    transform-origin: top center;
  }
  &:before {
    transition: $transOff;
  }
  &:after {
    @include calc(left,'100%');
    transition: $transEaseOut;
  }
  &.active {
    //color:$color-cyan;
    &:before {
      @include calc(left,'-100% + 20px');
    }
    &:after {
      @include calc(left,'-100% + 20px');
    }
  }
  &:hover {
    //color:$color-cyan;
    &:before {
      left:0;
      transition: $transEaseIn;
    }
    &:after {
      transition: $transEaseOut;
      transition-delay: $transTime;
      left:0;
    }
  }
  &.small {
    &:before,
    &:after {
      height:1px;
    }
  }
}
body.device-mobile .link-underline-leftright {
  &:before, &:after {
    display: none;
  }
  &:before:hover, &:after:hover {
    display: none;
  }
}


.link-underline-leftright-dark {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color:  #000;
  }
}
.link-underline-leftright-red {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color:  $color-hover;
  }
}
.link-underline-leftright-white {
  @extend .link-underline-leftright;
  &:before,
  &:after {
    background-color: #fff;
  }
}

//elipse
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-underline-grow {
  color: $color-hover;
  @include font-global-bold();
  font-size:18px;
  line-height: 22px;
  text-transform: uppercase;
  @media only screen and (max-width: $breakpoint-mobile-nav) {
    font-size:15px;
    line-height: 15px;
  }
}

.generic-image-hover {
  cursor: pointer;
  opacity:1;
  @include transition-opacity();
  @include punch-in-hover();
}
.scrim {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: rgb(26,98,137);
  background: linear-gradient(180deg, rgba(26,98,137,1) 0%, rgba(7,64,96,1) 68%, rgba(0,55,85,1) 100%);
}


.global-close {
  width:40px;
  height: 40px;
  display: block;
  cursor: pointer;
  &:before, &:after {
    content:"";
    width:40px;
    height: 5px;
    border-radius: 0;
    background-color: #fff;
    position: absolute;
    top:16px;
    left:0;
    @include transition-all();
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    &:before, &:after {
      transform: rotate(0deg);
      //background-color: $color-hover;
    }
  }
  &.dark {
    &:before, &:after {
      background-color: #000;
    }
  }
}


.below-header {
  padding-top: $headerTall;
  @media only screen and (max-width: $breakpoint-mobile-nav) {
    //padding-top: $headerSmall;
    padding-top: 0;
  }
}




.social-icons {
  display: inline-flex;
  align-items: center;
  height: 100%;
  .icon {
    margin:0 10px;
    &:first-child {
      margin-left: 0;
    }
    @include transition-all();
    svg {
      height: 36px;
      width: 36px;
      position: relative;
      top:2px;
      path {
        fill:#000;
        @include transition-all();
      }
    }
    &:hover {
      opacity: 0.6;
      svg {
        path {
          //fill: $color-hover;
        }
      }
    }
  }
  &.sidenav {
    .icon {
      svg {
        height: 40px;
        width: 40px;
      }
    }
  }
}


input {
  border:1px solid #B5B5B5;
  padding:5px 10px;
  @include set-input-placeholder-color(rgba(121,121,121,0.8));
  color: $color-gray;
}

.btn {
  border: 1px solid #000;
  background-color: #fff;
  @include font-roboto-medium();
  font-size: 15px;
  line-height: 1em;
  color:#000!important;
  text-transform: uppercase;
  //padding:5px 8px;
  padding:15px 18px;
  border-radius:0;

  cursor: pointer;
  @include transition-all();
  &:hover {
    border: 1px solid #fff;
    background-color:  #000;
    color:#fff!important;
  }
  &.big {
    padding:15px 18px;
  }
  &.light {
    padding:10px 13px;
    @include font-roboto-regular();
    display: block;
    width: 100%;
  }
}


.swiper-button-next, .swiper-button-prev {
  width:52px;
  height: 52px;
  //border-radius: 50%;
  //background-color: #000;
  //border:1px solid #3A3A3A;
  @include transition-all();
  svg {
    width:30px;
    height: auto;
    @include transition-all();
    path {
      fill:#fff;
    }
  }
  &:after {
    display: none;
  }
  &:hover {
    //width:62px;
    //height: 62px;
    //transform: translateX(-5px) translateY(-5px);
    //transform: scale(1.2);
  }

}
.swiper-button-next {
  right:-65px;
  &:hover {
    //border:1px solid #fff;
    svg {
      transform: scale(1.1);
      //transform: translateX(2px) scaleX(1.1);
    }
  }
}
.swiper-button-prev {
  left:-65px;
  svg {
      transform: rotate(180deg) scale(1 );
  }
  &:hover {
    //border:1px solid #fff;
    svg {
      transform: rotate(180deg) scale(1.1);
      //transform: translateX(-2px) scaleX(1.1);
    }
  }
}


.line {
  //border-top: 1px solid #B5B5B5;
  height: 1px;
  background-color: #000;
  width: 100%;
  @include master-margin-tb();
}


.expandables {
  .row {
    .top {
      display: flex;
      align-items: center;
      cursor: pointer;
      .rowl {
        width:100%;
        * {
          @include font-global-bold();
        }
      }
      .rowr {
        @include widthMaxMin(16px);
        margin-left: 10px;
        top:1px;
        position: relative;
        svg {
          width:100%;
          @include transition-all();
          transform: rotate(-90deg);
        }
      }
    }
    .bottom {
      height: 0;
      opacity: 0;
      overflow: hidden;
      @include transition-all();
      max-height: 100000px;
    }
    .line {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &.open {
      .top {
        .rowr {
          svg {
            transform: rotate(0deg);
          }
        }
      }
      .bottom {
        opacity: 1;
        .bottom2 {
        }
      }
    }
    &:last-child {
      .line {
        display: none;
      }
    }
  }//row
}

.border-hover {
  @include transition-all();
  border-radius: 6px;
  transform: scale(0.95);
  aspect-ratio: 1;
  .bgimg {
    opacity: 0.3;
    border-radius: 6px!important;
    @include transition-all();
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    left:6px;
    top:6px;
  }
  @include transition-all();
  //filter: drop-shadow(0px 0px 4px rgba(0,0,0,0));
  &:hover {
    transform: scale(1);
    .bgimg {
      opacity: 1;
    }
    background-color: rgba(39, 39, 39, 1);
    //filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.45));
  }
}
.border-hover-active {
  transform: scale(1);
  background-color: rgba(39, 39, 39, 1);
  //filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.45));
  .bgimg {
    opacity: 1;
  }
}

.filter-holder {
  .show-mobile.block {
    margin: 20px 0;
    .combobox {

    }
  }
}

.jump {
  cursor: pointer;
  @include transition-all();
  color: #666;
  @include font-global-bold();
  &:hover {
    color: $color-hover;
  }
}

.also-in-hover {
  @include transition-all();
  * {
    @include transition-all();
  }
  svg {
    path {
      @include transition-all();
    }
  }
  &:hover {
    color:#fff!important;
    * {
      color:#fff!important;
    }
    svg {
      path {
        fill: #fff!important;
      }
    }
  }
}


.website-social-bar {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  .bar {
    border-left: 1px solid #B5B5B5;
    margin-left: 10px;
    padding-left: 10px;
    &:first-child {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }

    .social-icons {
      .icon {
        a {
          svg {
            //opacity: 1;
            path {
              //fill: #000;
            }
          }
          &:hover {
            svg {
              //opacity: 0.8;
              path {
                //fill:  $color-hover;
              }
            }
          }
        }

      }
    }
  }
}

.simple-underline-on-hover {
  @include transition-all();
  border-bottom:1px solid transparent;

  &:hover {
    //text-decoration: underline;
    border-bottom:1px solid  $color-hover;
  }
  &.dark {
    &:hover {
      //text-decoration: underline;
      border-bottom:1px solid $color-dark;
    }
  }

}


.content-block {
  margin:100px 0;
  @media only screen and (max-width: $breakpoint-small) {
    margin:40px 0;
  }
  .bgimg-outer {
    background-color: #000;
  }
  .quick-one-swipe {
    aspect-ratio: 1.9;
    height: auto;
    .swiper-slide {
      .caption {
        display: none;
      }
    }
  }
  #quick-one-captions {
    margin-top: 5px;
    display: block;
    font-size: 12px;
    color:#000;
    * {
      font-size: 12px;
    }
  }
}



.square-strip {
  width:100%;
  .item {
    height: 100%;
    aspect-ratio: 1/1;
  }
}

.email-member-strip {
  margin-top: 40px;
  .email-flex {
    display: flex;
    .field-submit {
      margin-left: 20px;
    }
  }
  .field-email {
    @include font-adrianna-regular();
    font-size: 16px;
    border:1px solid #000;
    padding:10px 15px;
    @include font-adrianna-regular();
    font-size: 16px;
    color:#919191;
    @include set-input-placeholder-color(#919191);
    min-width: 200px;
  }
  .email-results {
    margin-top: 10px;
    min-height: 30px;
  }
  .field-submit {
    span {
      top:5px;
      @include font-adrianna-regular();
      font-size: 16px;
      @extend .link-underline-leftright-dark;
      background-color: transparent;
      border:0 solid #000;
      cursor: pointer;
      color:#000;
    }

  }
}

.video-thumb {
  width: 100%;
  //aspect-ratio: 1;
  aspect-ratio: 16/9;
  height: auto;
  cursor: default;
  //pointer-events: none;
  .video-thumb2 {
    display: block;
    //insane weird ios aspect ratio bug
    width: 100%;
    height: 100%;
    //aspect-ratio: 16/9;
    background-color: #000000;
    //@include normal-hover();
    cursor: pointer;
    //@include willChange();
    @include punch-in-hover();
  }
  .icon {
    @include willChange();
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.images-strip-outer {
  height: auto;
  // WIDTH set via JS
}

.images-strip, .video-strip {
  .quick-thumbs {
    width: calc(100% - $master-padding);
    @media only screen and (max-width: $breakpoint-small) {
      width: calc(100% - $mobile-thumbs-peek);
    }
  }
}

.images-strip {
  width: 100%;
  //aspect-ratio: 4;
  .quick-thumbs {
    // set above
    height: 100%;
    .swiper-slide {
      cursor: pointer;
      aspect-ratio: 1;
      @include punch-in-hover();
      .icon {
        @include willChange();
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }
}

.trailer-bugs {
  margin:20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  .label, .label-data {
    margin-bottom: 10px;
  }
  .label {
    @include widthMaxMin(110px);
  }
  .label-data {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  div {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  .bugs, .official-trailer, .label {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    .label {
      margin-right: 10px;
    }
    .txt {
      text-transform: uppercase;
      @extend .link-underline-leftright-dark;
      color:#000;
      cursor: pointer;
    }
    .icon {
      margin-left: 10px;
      top:1px;
    }
  }
  .bug-icon.disney {
    margin-top: -10px;
  }
  .bug-icon.hulu {
    margin-right:5px;
  }
  a {
    img {
      @include transition-all();
    }
    &:hover {
      opacity: 0.75;
    }
  }
  .ellipsis-pad {
   top:3px!important;
  }
  .label {
    top:3px;
  }

}




.film-top {
  .lr {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }

    .l {
      @include widthMaxMin($filmColumn);
      img {
        width: 100%;
        height: auto;
      }
      @media only screen and (max-width: $breakpoint-small) {
        @include widthMaxMin(80%);
        margin: 0 auto;
      }
    }

    .r {
      width: 100%;
      margin-left: 40px;
      @media only screen and (max-width: $breakpoint-small) {
        margin-left: 0;
        margin-top: 40px;
      }
    }
  }
  &.no-poster {
    .l {
      display: none;
    }
    .r {
      margin-left: 0;
    }
  }

  //lr
  .video-thumb {
    margin-top: 20px;
    width: 100%;
  }

  .synopsis {
    margin-top: 20px;
    .synopsis2 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media only screen and (max-width: 1150px) {
        display: block;
      }
      @media only screen and (max-width: $breakpoint-small) {
        display: block;
      }
      .synopsis-left {
        width: 100%;
        margin-right: 120px;
        @media only screen and (max-width: $breakpoint-small) {
          margin-right: 0;
        }
      }
      .synopsis-right {
        @include widthMaxMin(340px);
        .line {
          margin:25px 0 25px;
        }
        .btn-outer {
          display: flex;
          margin-bottom: 15px;
          width: 100%;
          .btnouter {
            width: 100%;
          }
          a {
            display: block;
            width: 100%;
          }
          &.lr {
            justify-content: space-between;
            .btnouter {
              width:calc(50% - 5px);
            }
            .btn {
              width:100%;
            }
          }

        }
      }
    }
  }

  .status {
    @include font-adrianna-condensed-regular();
    font-size: 25px;
    text-transform: uppercase;

    span {
      margin-right: 10px;
    }
  }

  &.do-showtimes {
    @media only screen and (max-width: $breakpoint-small) {
      .lr {
        .l {
          display: none;
        }
      }
    }
  }
}

.poster-graphic {
  background-color: #000;
}


.ellipsis-outer {
  display: inline-flex;
  align-items: center;
  .ellipsis-pad {
    padding:10px;
    cursor: pointer;
    top:10px;
    left:10px;
    .ellipsis {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      @keyframes scaleDot {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.2); /* Scale up to 1.2 at 50% */
        }
        100% {
          transform: scale(1); /* Scale back to 1 at 100% */
        }
      }
      .dot {
        margin:0 2px;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        transition: transform 0.2s ease;
        @include willChange();
      }
    }//ellipses
    &:hover {
      .dot-1 {
        animation: scaleDot 0.4s ease forwards;
        animation-delay: 0s;
      }
      .dot-2 {
        animation: scaleDot 0.4s ease forwards;
        animation-delay: 0.05s;

      }
      .dot-3 {
        animation: scaleDot 0.4s ease forwards;
        animation-delay: 0.1s;
      }
    }//hover
  }
  &.with-label, &.dark {
    .ellipsis-pad {
      top:-3px;
      left:0;
      margin: 0 0 0 5px;
      .ellipsis {
        .dot {
          background-color: #000;
        }
      }
    }
  }
}


.strip-hider-trigger {
  overflow: hidden;
}




.loading-text {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-start;
  .ellipse-loading {
    position: relative;
    width: 40px;
    margin-left: 10px;
    .dot {
      display: inline-block;
      margin:0 0px;
      width: 4px;
      height: 4px;
      background-color: #000;
      border-radius: 50%;
      transition: transform 0.2s ease;
      @include willChange();
      animation: dotAnimation 0.3s linear infinite;
    }

    @keyframes dotAnimation {
      0%, 100% {
        transform: scale(1);
        opacity:1;
      }
      50% {
        transform: scale(1.3);
        opacity:0.8;
      }
    }
    .dot:nth-child(1) {
      animation-delay: 0s;
    }
    .dot:nth-child(2) {
      animation-delay: 0.1s;
    }
    .dot:nth-child(3) {
      animation-delay: 0.2s;
    }
  }

}

.icon-link {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  @extend .link-underline-leftright-dark;
  .txt {
    color:#000;
    text-transform: uppercase;
    @include font-adrianna-regular();
    font-size: 14px;
  }
  .icon {
    margin-left: 10px;
    svg {
      width: 15px;
      path {
        fill:#000;
      }
    }
  }
}

.line {
  margin:10px 0;
  display:block;
  width:100%;
  height: 1px;
  background-color: #000;
}


.roles {
  .role-row {
    margin-bottom: 5px;
    h1, h2, h3, h4, h5, h6, p {
      margin: 0;
      display: inline;
    }
  }
}

.flex-lr {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .r {
    display: flex;
    justify-content: flex-end;
  }
}

.download-all {
  //width: 100%;
  margin-bottom: 10px;
  .download-all-action-link, .download-all-action-link-manual {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @extend .link-underline-leftright-dark;
    .txt {
      color:#000;
      text-transform: uppercase;
      @include font-adrianna-regular();
    }
    .icon {
      margin-left: 10px;
      svg {
        width: 13px;
        path {
          fill:#000;
        }
      }
    }
  }
}

.request-account-label {
  margin:15px 0 13px;
  text-align: center;
  p {
    line-height: 1em;
    font-size: 13px;
    text-align: center;
  }
}